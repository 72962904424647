exports.components = {
  "component---src-pages-2-0-upgrade-guide-tsx": () => import("./../../../src/pages/2_0_upgrade_guide.tsx" /* webpackChunkName: "component---src-pages-2-0-upgrade-guide-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ambassadors-tsx": () => import("./../../../src/pages/ambassadors.tsx" /* webpackChunkName: "component---src-pages-ambassadors-tsx" */),
  "component---src-pages-careers-job-tsx": () => import("./../../../src/pages/careers/job.tsx" /* webpackChunkName: "component---src-pages-careers-job-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-developer-newsletter-tsx": () => import("./../../../src/pages/developer-newsletter.tsx" /* webpackChunkName: "component---src-pages-developer-newsletter-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-exchanges-tsx": () => import("./../../../src/pages/exchanges.tsx" /* webpackChunkName: "component---src-pages-exchanges-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-mining-pools-tsx": () => import("./../../../src/pages/mining-pools.tsx" /* webpackChunkName: "component---src-pages-mining-pools-tsx" */),
  "component---src-pages-news-announcements-tsx": () => import("./../../../src/pages/news/announcements.tsx" /* webpackChunkName: "component---src-pages-news-announcements-tsx" */),
  "component---src-pages-news-chinese-tsx": () => import("./../../../src/pages/news/chinese.tsx" /* webpackChunkName: "component---src-pages-news-chinese-tsx" */),
  "component---src-pages-news-community-contests-tsx": () => import("./../../../src/pages/news/community-contests.tsx" /* webpackChunkName: "component---src-pages-news-community-contests-tsx" */),
  "component---src-pages-news-developer-community-tsx": () => import("./../../../src/pages/news/developer-community.tsx" /* webpackChunkName: "component---src-pages-news-developer-community-tsx" */),
  "component---src-pages-news-events-tsx": () => import("./../../../src/pages/news/events.tsx" /* webpackChunkName: "component---src-pages-news-events-tsx" */),
  "component---src-pages-news-featured-tsx": () => import("./../../../src/pages/news/featured.tsx" /* webpackChunkName: "component---src-pages-news-featured-tsx" */),
  "component---src-pages-news-giving-back-tsx": () => import("./../../../src/pages/news/giving-back.tsx" /* webpackChunkName: "component---src-pages-news-giving-back-tsx" */),
  "component---src-pages-news-inclusion-empowerment-tsx": () => import("./../../../src/pages/news/inclusion-empowerment.tsx" /* webpackChunkName: "component---src-pages-news-inclusion-empowerment-tsx" */),
  "component---src-pages-news-interviews-tsx": () => import("./../../../src/pages/news/interviews.tsx" /* webpackChunkName: "component---src-pages-news-interviews-tsx" */),
  "component---src-pages-news-media-mention-tsx": () => import("./../../../src/pages/news/media-mention.tsx" /* webpackChunkName: "component---src-pages-news-media-mention-tsx" */),
  "component---src-pages-news-news-releases-tsx": () => import("./../../../src/pages/news/news-releases.tsx" /* webpackChunkName: "component---src-pages-news-news-releases-tsx" */),
  "component---src-pages-news-our-brand-journey-tsx": () => import("./../../../src/pages/news/our-brand-journey.tsx" /* webpackChunkName: "component---src-pages-news-our-brand-journey-tsx" */),
  "component---src-pages-news-our-people-tsx": () => import("./../../../src/pages/news/our-people.tsx" /* webpackChunkName: "component---src-pages-news-our-people-tsx" */),
  "component---src-pages-news-partnerships-tsx": () => import("./../../../src/pages/news/partnerships.tsx" /* webpackChunkName: "component---src-pages-news-partnerships-tsx" */),
  "component---src-pages-news-press-releases-tsx": () => import("./../../../src/pages/news/press-releases.tsx" /* webpackChunkName: "component---src-pages-news-press-releases-tsx" */),
  "component---src-pages-news-product-releases-upgrades-tsx": () => import("./../../../src/pages/news/product-releases-upgrades.tsx" /* webpackChunkName: "component---src-pages-news-product-releases-upgrades-tsx" */),
  "component---src-pages-news-project-reports-tsx": () => import("./../../../src/pages/news/project-reports.tsx" /* webpackChunkName: "component---src-pages-news-project-reports-tsx" */),
  "component---src-pages-news-security-privacy-tsx": () => import("./../../../src/pages/news/security-privacy.tsx" /* webpackChunkName: "component---src-pages-news-security-privacy-tsx" */),
  "component---src-pages-news-sidechain-innovations-tsx": () => import("./../../../src/pages/news/sidechain-innovations.tsx" /* webpackChunkName: "component---src-pages-news-sidechain-innovations-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-news-tutorials-tsx": () => import("./../../../src/pages/news/tutorials.tsx" /* webpackChunkName: "component---src-pages-news-tutorials-tsx" */),
  "component---src-pages-node-hosting-tsx": () => import("./../../../src/pages/node-hosting.tsx" /* webpackChunkName: "component---src-pages-node-hosting-tsx" */),
  "component---src-pages-opt-in-success-tsx": () => import("./../../../src/pages/opt-in-success.tsx" /* webpackChunkName: "component---src-pages-opt-in-success-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-supernodes-tsx": () => import("./../../../src/pages/supernodes.tsx" /* webpackChunkName: "component---src-pages-supernodes-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-wallets-cobalt-tsx": () => import("./../../../src/pages/wallets/cobalt.tsx" /* webpackChunkName: "component---src-pages-wallets-cobalt-tsx" */),
  "component---src-pages-wallets-spherebyhorizen-tsx": () => import("./../../../src/pages/wallets/spherebyhorizen.tsx" /* webpackChunkName: "component---src-pages-wallets-spherebyhorizen-tsx" */),
  "component---src-pages-wallets-tsx": () => import("./../../../src/pages/wallets.tsx" /* webpackChunkName: "component---src-pages-wallets-tsx" */),
  "component---src-pages-zen-tsx": () => import("./../../../src/pages/zen.tsx" /* webpackChunkName: "component---src-pages-zen-tsx" */),
  "component---src-pages-zendoo-tsx": () => import("./../../../src/pages/zendoo.tsx" /* webpackChunkName: "component---src-pages-zendoo-tsx" */),
  "component---src-pages-zennodes-tsx": () => import("./../../../src/pages/zennodes.tsx" /* webpackChunkName: "component---src-pages-zennodes-tsx" */)
}

